@import '../../styles/screen-sizes';

.hamburger-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: none;
  padding: none;

  @include on-tablet {
    display: block;
  }
}
