@import '../../styles/style-guide';
@import '../../styles/gradients';
@import '../../styles/screen-sizes';

.hero {
  height: 100%;
  overflow: hidden;
  width: 100%;

  .hero-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    @include on-mobile {
      background: url('../../../public/assets/mobile-hero-img.png');
      background-position: center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    .bck-video {
      height: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: -10;

      @include on-mobile {
        display: none;
      }
    }

    h1 {
      color: $light;
      font-size: 110px;
      font-weight: 400;
      margin: 0;
      text-align: start;

      @include on-tablet {
        font-size: 80px;
      }

      @include on-mobile {
        font-size: 40px;
      }
    }

    .emp-text {
      @include text-gradient-fill;
      @include text-gradient-clip;
      font-weight: 700;
      text-align: start;
    }
  }
}
