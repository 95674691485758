@import './style-guide';

@mixin gradient {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
}

@mixin text-gradient-fill {
  @include gradient;
  background-color: $primary;
  background-size: 100%;
}


@mixin text-gradient-clip {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

@mixin border-gradient {
  border-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%) 1;
  border-style: solid;
  border-width: 1px;
}
