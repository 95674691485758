@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.jobs-list {
  align-items: center;
  background-color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  position: absolute;
  right: 0;
  top: 30%;
  width: 60%;
  z-index: 1;

  @include on-tablet {
    position: unset;
    right: unset;
    top: unset;
    width: 100%;
  }
}
