@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap";
html {
  box-sizing: border-box;
  height: 100%;
}

html * {
  color: #0b0a10;
  font-family: Montserrat, sans-serif;
}

html *, html :before, html :after {
  box-sizing: inherit;
}

html body {
  height: 100%;
  background-color: #0b0a10;
  margin: 0;
}

html .container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
}

.app {
  color: #edf1f3;
  text-align: center;
}

.hero {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.hero .hero-container {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media only screen and (max-width: 428px) {
  .hero .hero-container {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background: url("mobile-hero-img.b010ac30.png") center / cover no-repeat;
  }
}

.hero .hero-container .bck-video {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: -10;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 428px) {
  .hero .hero-container .bck-video {
    display: none;
  }
}

.hero .hero-container h1 {
  color: #edf1f3;
  text-align: start;
  margin: 0;
  font-size: 110px;
  font-weight: 400;
}

@media only screen and (max-width: 960px) {
  .hero .hero-container h1 {
    font-size: 80px;
  }
}

@media only screen and (max-width: 428px) {
  .hero .hero-container h1 {
    font-size: 40px;
  }
}

.hero .hero-container .emp-text {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-color: #845eff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: start;
  background-clip: text;
  font-weight: 700;
}

.text {
  opacity: 0;
  width: 100%;
  flex-direction: column;
  transition-timing-function: cubic-bezier(.785, .135, .15, .86);
  animation: fade-in 2.4s forwards;
  display: flex;
}

.text .top-element {
  z-index: 1;
  animation: top-element 1.8s cubic-bezier(.785, .135, .15, .86) forwards;
  display: inline-block;
  position: relative;
}

.text .slide {
  display: inline-block;
  overflow: hidden;
}

.text .slide .bottom-element {
  z-index: -1;
  animation: bottom-element 1.8s cubic-bezier(.785, .135, .15, .86) forwards;
  display: inline-block;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes top-element {
  0% {
    transform: translateX(25%);
  }

  60% {
    transform: translateX(25%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes bottom-element {
  0% {
    transform: translateX(-160%);
  }

  60% {
    transform: translateX(-160%);
  }

  100% {
    transform: translateX(0%);
  }
}

.icon-button {
  height: 62px;
  min-width: 62px;
  position: relative;
  overflow: hidden;
}

.icon-button .button {
  height: 100%;
  width: 100%;
  background-color: #0000;
  border: 1px solid #edf1f3;
  padding: 0;
  transition: transform .4s;
}

.icon-button .button:hover {
  cursor: pointer;
}

.icon-button .button-overlay {
  height: 100%;
  width: 100%;
  z-index: -1;
  background-color: #edf1f3;
  transition: transform .4s;
  position: absolute;
  bottom: 0;
  left: -100%;
}

.text-button {
  border: unset;
  height: 60px;
  text-align: center;
  padding: 0 60px;
  font-size: 16px;
  font-weight: 500;
}

.text-button:hover {
  cursor: pointer;
}

.text-button.primary {
  color: #edf1f3;
  background-color: #0b0a10;
  transition: all .4s;
}

.text-button.primary:hover {
  background-color: #0b0a10b3;
  transition: all .4s;
}

.text-button.secondary {
  color: #0b0a10;
  background-color: #edf1f3;
  transition: all .4s;
}

.text-button.secondary:hover {
  background-color: #edf1f3b3;
  transition: all .4s;
}

.text-button.ghost {
  color: #edf1f3;
  background-color: #0000;
  border: 1px solid #edf1f3;
  transition: all .4s;
}

.text-button.ghost:hover {
  color: #0b0a10;
  background-color: #edf1f3;
  transition: all .4s;
}

.job-item {
  width: 70%;
  margin: 40px auto;
}

@media only screen and (max-width: 960px) {
  .job-item {
    width: 100%;
    padding: 0 40px;
  }
}

.job-item:hover > .links > .job-title:after {
  width: 100%;
}

.job-item:hover > .links > .icon-button > .button + .button-overlay {
  transform: translateX(100%);
}

@media only screen and (max-width: 960px) {
  .job-item:hover > .links > .icon-button > .button + .button-overlay {
    transform: unset;
  }
}

.job-item:hover > .links > .icon-button > .button > svg {
  fill: #0b0a10;
}

@media only screen and (max-width: 960px) {
  .job-item:hover > .links > .icon-button > .button > svg {
    fill: #edf1f3;
  }
}

.job-item .links {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.job-item .links .job-title {
  color: #edf1f3;
  text-align: start;
  padding: 8px 24px 8px 0;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
  text-decoration: none;
  position: relative;
}

@media only screen and (max-width: 428px) {
  .job-item .links .job-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.job-item .links .job-title:after {
  content: "";
  height: 2px;
  width: 0;
  background-color: #edf1f3;
  transition: width .4s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 960px) {
  .job-item .links .job-title:after {
    all: unset;
  }
}

.jobs-list {
  width: 60%;
  z-index: 1;
  background-color: #0b0a10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  display: flex;
  position: absolute;
  top: 30%;
  right: 0;
}

@media only screen and (max-width: 960px) {
  .jobs-list {
    position: unset;
    right: unset;
    top: unset;
    width: 100%;
  }
}

.quote-card-border {
  height: 70%;
  width: 44%;
  z-index: 2;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%) 1;
  position: absolute;
  top: 12px;
  left: 12px;
}

@media only screen and (max-width: 960px) {
  .quote-card-border {
    height: unset;
    left: unset;
    position: unset;
    top: unset;
    width: unset;
    border: 0;
  }
}

.quote-card-border .quote-card-fill {
  height: 100%;
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: -16px;
  left: -16px;
}

@media only screen and (max-width: 960px) {
  .quote-card-border .quote-card-fill {
    width: 100%;
    top: 0;
    left: 0;
  }
}

.quote-card-border .quote-card-fill .quote-text {
  color: #edf1f3;
  text-align: start;
  margin: auto 30%;
  font-size: 80px;
  font-weight: 400;
  line-height: 98px;
}

@media only screen and (max-width: 960px) {
  .quote-card-border .quote-card-fill .quote-text {
    margin: 140px 40px;
    font-size: 64px;
    line-height: 78px;
  }
}

@media only screen and (max-width: 428px) {
  .quote-card-border .quote-card-fill .quote-text {
    margin: 100px 40px 80px;
    font-size: 40px;
    line-height: 50px;
  }
}

.background-texture {
  z-index: -10;
  position: absolute;
}

.background-texture.black {
  bottom: 5%;
  left: 0;
}

@media only screen and (max-width: 960px) {
  .background-texture.black {
    display: none;
  }
}

.background-texture.gradient {
  right: 40px;
  overflow: hidden;
}

@media only screen and (max-width: 960px) {
  .background-texture.gradient {
    height: 900px;
    top: 60%;
    left: 60%;
  }
}

@media only screen and (max-width: 428px) {
  .background-texture.gradient {
    max-width: 100px;
    top: 140%;
    left: 70%;
    right: 0;
  }
}

.careers-page {
  height: 100vh;
}

@media only screen and (max-width: 960px) {
  .careers-page {
    height: unset;
  }
}

.job-container {
  color: #0b0a10;
  text-align: start;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 24px;
  line-height: 32px;
  display: flex;
}

@media only screen and (max-width: 428px) {
  .job-container {
    font-size: 16px;
    line-height: 20px;
  }
}

.job-container .title {
  margin-bottom: 24px;
  padding-top: 160px;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
}

@media only screen and (max-width: 428px) {
  .job-container .title {
    padding-top: 100px;
    font-size: 24px;
    line-height: 32px;
  }
}

.job-container .tags {
  flex-direction: row;
  margin-bottom: 20px;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .job-container .tags {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.job-container .tags .divider-small {
  content: "";
  height: 2px;
  width: 20px;
  background-color: #845eff;
  align-self: center;
  margin: 0 20px;
}

@media only screen and (max-width: 428px) {
  .job-container .tags .divider-small {
    width: 12px;
    margin: 0 8px;
  }
}

.job-container .description {
  max-width: 1000px;
}

@media only screen and (max-width: 960px) {
  .job-container .description {
    max-width: 100%;
  }
}

.job-container .description p {
  margin: 60px 0;
}

@media only screen and (max-width: 428px) {
  .job-container .description p {
    margin: 32px 0;
  }
}

.job-container .description .divider-big {
  content: "";
  height: 2px;
  width: 400px;
  background-color: #845eff;
  align-self: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 428px) {
  .job-container .description .divider-big {
    width: 100%;
    margin-bottom: 8px;
  }
}

.job-container button {
  margin: 100px 0 200px;
}

@media only screen and (max-width: 428px) {
  .job-container button {
    margin: 60px 0 120px;
  }
}

.job-details-page {
  height: 100%;
  width: 100%;
}

.list-section {
  margin: 30px 0;
}

@media only screen and (max-width: 428px) {
  .list-section {
    margin: 8px 0;
  }
}

.list-section .list-title {
  font-weight: 600;
}

.list-section .list {
  max-width: 1000px;
}

@media only screen and (max-width: 960px) {
  .list-section .list {
    max-width: 100%;
  }
}

.list-section .list p {
  margin: 4px 0;
  line-height: 32px;
}

@media only screen and (max-width: 428px) {
  .list-section .list p {
    margin: 0;
  }
}

.list-section .list ul {
  padding: 0;
  list-style: none;
}

.list-section .list .nested-list {
  margin: 4px 0 20px;
  padding-left: 60px;
  list-style-type: square;
  list-style-image: url("list-bullet.afd66d83.png");
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 960px) {
  .map-container {
    left: unset;
    position: unset;
    top: unset;
  }
}

.map-container .map {
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

@media only screen and (max-width: 960px) {
  .map-container .map {
    height: 70vh;
  }
}

.map-container .marker {
  position: absolute;
  transform: translate(-50%, -50%);
}

@keyframes echo {
  0% {
    height: 8px;
    opacity: 0;
    width: 8px;
  }

  30% {
    opacity: .3;
  }

  90% {
    opacity: .01;
  }

  100% {
    height: 200px;
    opacity: 0;
    width: 200px;
  }
}

.waves {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.dot {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  height: 8px;
  width: 8px;
  border-radius: 50%;
}

.wave {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  border-radius: 50%;
  animation-name: echo;
  animation-duration: 2.4s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  position: absolute;
}

.wave:nth-of-type(2) {
  animation-delay: .4s;
}

.wave:nth-of-type(3) {
  animation-delay: .8s;
}

.contact-card {
  background-color: #0b0a10;
}

.contact-card .border {
  height: 100%;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%) 1;
  position: relative;
  bottom: 12px;
  left: 12px;
}

@media only screen and (max-width: 960px) {
  .contact-card .border {
    bottom: unset;
    height: unset;
    left: unset;
    position: unset;
    border: 0;
  }
}

.contact-card .card {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 80px;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .contact-card .card {
    padding: 40px;
  }
}

.contact-card .card .title {
  color: #edf1f3;
  font-size: 40px;
  font-weight: 400;
  line-height: 50px;
}

@media only screen and (max-width: 428px) {
  .contact-card .card .title {
    font-size: 24px;
    line-height: 32px;
  }
}

.contact-card .card .info {
  text-align: start;
  width: 500px;
  margin-top: 60px;
  font-size: 24px;
  line-height: 32px;
}

@media only screen and (max-width: 428px) {
  .contact-card .card .info {
    width: 100%;
    margin-top: 40px;
    font-size: 16px;
    line-height: 20px;
  }
}

.contact-card .card .info p {
  color: #edf1f3;
  margin: 0;
}

.contact-page {
  height: 100vh;
  max-width: 1440px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 0 40px;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .contact-page {
    align-items: unset;
    height: unset;
    justify-content: unset;
    margin: unset;
    max-width: unset;
    padding: unset;
    flex-direction: column-reverse;
  }
}

.contact-page .contact-info {
  z-index: 1;
  align-items: center;
  margin-top: 100px;
}

@media only screen and (max-width: 960px) {
  .contact-page .contact-info {
    margin-top: unset;
    position: unset;
    right: unset;
    top: unset;
    width: 100%;
  }
}

.footer {
  background-color: #e2e7e9;
}

.footer .footer-container {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 60px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .footer .footer-container {
    flex-direction: column;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .footer .footer-container > * {
    width: 100%;
    margin-top: 40px;
  }
}

.footer .footer-container .first-row {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.footer .footer-container .first-row > svg {
  transform-origin: 0 0;
  transform: scale(1.6);
}

@media only screen and (max-width: 428px) {
  .footer .footer-container .first-row > svg {
    transform: scale(1.4);
  }
}

.footer .footer-container .first-row .social {
  display: flex;
}

.footer .footer-container .first-row .social .icon-link {
  margin-right: 20px;
  padding-top: 40px;
}

.footer .footer-container .second-row .menu-item {
  text-align: start;
  margin: 20px 0;
}

.footer .footer-container .third-row {
  text-align: end;
}

@media only screen and (max-width: 960px) {
  .footer .footer-container .third-row {
    text-align: start;
  }
}

.footer .footer-container .third-row a {
  color: #0b0a10;
  margin: 20px 0;
  text-decoration: none;
  display: block;
}

.footer .footer-container .copyrights-info {
  text-align: start;
  width: 100%;
  margin-top: 40px;
}

.menu-item .links {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}

.menu-item .links:hover {
  border-bottom: 4px solid #845eff;
  transition: all .2s ease-out;
}

.navbar {
  width: 100%;
  z-index: 100;
  background-color: #0000;
  transition: all .4s;
  position: fixed;
  top: 0;
}

.navbar.active {
  background-color: #0b0a10;
  transition: all .4s;
}

.navbar.active > .navbar-container > a.logo > svg {
  fill: #845eff !important;
}

.navbar.active > .navbar-container > .nav-menu > .menu-item .links {
  color: #edf1f3 !important;
}

.navbar.active > .navbar-container > .hamburger-icon > svg {
  fill: #edf1f3 !important;
}

.navbar .navbar-container {
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar .navbar-container > a.light-theme > svg {
  fill: #edf1f3;
}

.navbar .navbar-container > a.dark-theme > svg, .navbar .navbar-container > a.mid-light > svg {
  fill: #845eff;
}

.navbar .navbar-container > .hamburger-icon.mid-light > svg {
  fill: #0b0a10;
}

.hamburger-icon {
  cursor: pointer;
  padding: none;
  background-color: #0000;
  border: 0;
  display: none;
}

@media only screen and (max-width: 960px) {
  .hamburger-icon {
    display: block;
  }
}

.nav-menu {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 960px) {
  .nav-menu {
    height: 100vh;
    opacity: 0;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    transition: all .4s;
    position: absolute;
    top: 80px;
    right: -100%;
  }

  .nav-menu > .menu-item .links {
    color: #edf1f3 !important;
  }
}

.nav-menu.active {
  opacity: 1;
  z-index: 1;
  background-color: #0b0a10;
  transition: all .4s;
  right: 0;
}

@media only screen and (max-width: 960px) {
  .nav-menu .menu-item {
    width: 100%;
    margin: 60px 0;
    position: relative;
  }
}

@media only screen and (max-width: 428px) {
  .nav-menu .menu-item {
    margin: 20px 0;
  }
}

.nav-menu .menu-item .links {
  margin: 0 20px;
}

@media only screen and (max-width: 960px) {
  .nav-menu .menu-item .links {
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
  }

  .nav-menu .menu-item .links:hover {
    color: #845eff;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 428px) {
  .nav-menu .menu-item .links {
    font-size: 32px;
    line-height: 40px;
  }
}

.nav-menu.dark > .menu-item .links {
  color: #edf1f3;
}

.nav-menu.light > .menu-item .links, .nav-menu.mid-light > .menu-item .links {
  color: #0b0a10;
}

.interactive-card {
  letter-spacing: normal;
  height: 300px;
  width: 100%;
  background-color: #0b0a10;
  border: 1px solid #edf1f3;
  font-size: 40px;
  line-height: 49px;
}

@media only screen and (max-width: 428px) {
  .interactive-card {
    letter-spacing: normal;
    height: 140px;
    font-size: 24px;
    line-height: 34px;
  }
}

.interactive-card .children-wrapper {
  height: 100%;
  width: 100%;
  background-color: #0b0a10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.interactive-card .children-wrapper:hover {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-color: #845eff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  cursor: pointer;
  background-clip: text;
  font-weight: bold;
  transition: all .2s;
}

.gradient {
  top: 180px;
}

.about-us-page {
  flex-direction: column;
  padding-top: 220px;
  display: flex;
}

@media only screen and (max-width: 428px) {
  .about-us-page {
    margin-top: 140px;
  }
}

.about-us-page * {
  color: #edf1f3;
  text-align: left;
}

.about-us-page .heading {
  letter-spacing: normal;
  text-align: left;
  font-size: 60px;
  font-weight: bold;
  line-height: 73px;
  display: inline-block;
}

@media only screen and (max-width: 428px) {
  .about-us-page .heading {
    letter-spacing: normal;
    font-size: 40px;
    font-weight: bold;
    line-height: 49px;
  }

  .about-us-page .heading > span {
    display: block;
  }
}

.about-us-page .heading > span {
  margin-right: 22px;
}

.about-us-page .heading.column {
  display: inline-block;
}

.about-us-page .heading.align-left {
  text-align: left;
}

.about-us-page .content {
  letter-spacing: normal;
  max-width: 1000px;
  text-align: left;
  margin-top: 100px;
  font-size: 24px;
  line-height: 34px;
}

@media only screen and (max-width: 428px) {
  .about-us-page .content {
    letter-spacing: normal;
    margin-top: 60px;
    font-size: 16px;
    line-height: 34px;
  }
}

.about-us-page .principles {
  margin: 200px 0;
}

@media only screen and (max-width: 428px) {
  .about-us-page .principles {
    margin: 100px 0;
  }
}

.about-us-page .emp-text {
  background-image: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-image: conic-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);
  background-color: #845eff;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-align: inherit;
  background-clip: text;
  position: relative;
}

.about-us-page .boxes {
  grid-template-columns: auto 36% 36%;
  grid-template-areas: ". item-1 item-2"
                       "item-3 item-3 .";
  grid-auto-rows: 1fr;
  margin-top: 80px;
  display: grid;
}

@media only screen and (max-width: 960px) {
  .about-us-page .boxes {
    grid-template-areas: "item-1 item-1 item-1"
                         "item-2 item-2 item-2"
                         "item-3 item-3 item-3";
    margin-top: 40px;
  }
}

.about-us-page .boxes .item-1 {
  grid-area: item-1;
}

.about-us-page .boxes .item-2 {
  grid-area: item-2;
}

.about-us-page .boxes .item-3 {
  grid-area: item-3;
}

/*# sourceMappingURL=index.e19e59c7.css.map */
