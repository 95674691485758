@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.navbar {
  background-color: transparent;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
  width: 100%;
  z-index: 100;

  &.active {
    background-color: $dark;
    transition: all 0.4s ease;

    > .navbar-container {
      > a {
        &.logo {
          > svg {
            fill: $primary !important;
          }
        }
      }
    }

    > .navbar-container {
      > .nav-menu {
        > .menu-item {
          .links {
            color: $light !important;
          }
        }
      }
    }

    > .navbar-container {
      > .hamburger-icon {
        > svg {
          fill: $light !important;
        }
      }
    }
  }

  .navbar-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 80px;
    justify-content: space-between;

    > a {
      &.light-theme {
        > svg {
          fill: $light;
        }
      }

      &.dark-theme {
        > svg {
          fill: $primary;
        }
      }

      &.mid-light {
        > svg {
          fill: $primary;
        }
      }
    }

    > .hamburger-icon {
      &.mid-light {
        > svg {
          fill: $dark;
        }
      }
    }
  }
}
