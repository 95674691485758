@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.job-item {
  margin: 40px auto;
  width: 70%;

  @include on-tablet {
    padding: 0 40px;
    width: 100%;
  }

  &:hover {
    > .links {
      > .job-title::after {
        width: 100%;
      }

      > .icon-button {
        > .button + .button-overlay {
          transform: translateX(100%);

          @include on-tablet {
            transform: unset;
          }
        }

        > .button {
          > svg {
            fill: $dark;

            @include on-tablet {
              fill: $light;
            }
          }
        }
      }
    }
  }

  .links {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-decoration: none;

    .job-title {
      color: $light;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;
      padding: 8px 24px 8px 0;
      position: relative;
      text-align: start;
      text-decoration: none;

      @include on-mobile {
        font-size: 24px;
        line-height: 32px;
      }

      &::after {
        background-color: $light;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        transition: width 0.4s ease 0s;
        width: 0;

        @include on-tablet {
          all: unset;
        }
      }
    }
  }
}
