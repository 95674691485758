@import '../../styles/style-guide';

.icon-button {
  height: 62px;
  min-width: 62px;
  overflow: hidden;
  position: relative;

  .button {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $light;
    height: 100%;
    padding: 0;
    transition: transform 0.4s ease;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .button-overlay {
    background-color: $light;
    bottom: 0;
    height: 100%;
    left: -100%;
    position: absolute;
    transition: transform 0.4s ease;
    width: 100%;
    z-index: -1;
  }
}

.text-button {
  border: unset;
  font-size: 16px;
  font-weight: 500;
  height: 60px;
  padding: 0 60px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &.primary {
    background-color: $dark;
    color: $light;
    transition: all 0.4s ease;

    &:hover {
      background-color: rgba(11, 10, 16, 0.7);
      transition: all 0.4s ease;
    }
  }

  &.secondary {
    background-color: $light;
    color: $dark;
    transition: all 0.4s ease;

    &:hover {
      background-color: rgba(237, 241, 243, 0.7);
      transition: all 0.4s ease;
    }
  }

  &.ghost {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid $light;
    color: $light;
    transition: all 0.4s ease;

    &:hover {
      background-color: $light;
      color: $dark;
      transition: all 0.4s ease;
    }
  }
}
