@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.careers-page {
  height: 100vh;

  @include on-tablet {
    height: unset;
  }
}
