@import '../../styles/gradients';
@import '../../styles/keyframes';

@include keyframes(echo) {
  0% {
    height: 8px;
    opacity: 0;
    width: 8px;
  }

  30% {
    opacity: 0.3;
  }

  90% {
    opacity: 0.01;
  }

  100% {
    height: 200px;
    opacity: 0;
    width: 200px;
  }
}

.waves {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: relative;
  top: 0;
}

.dot {
  @include gradient;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.wave {
  @include gradient;
  animation-duration: 2.4s;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  animation-name: echo;
  animation-name: echo;
  animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  border-radius: 50%;
  position: absolute;
}

.wave:nth-of-type(2) {
  animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.wave:nth-of-type(3) {
  animation-delay: 0.8s;
  animation-delay: 0.8s;
}
