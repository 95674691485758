// -- Colors

$primary: #845eff;
$light: #edf1f3;
$gray: #e2e7e9;
$dark: #0b0a10;

// - Gradients

$gradient-fill: linear-gradient(45deg, #4801ff 0%, #7918f2 30%, #ac32e4 100%);

// -- Paddings and sizes

$max-container-width: 1440px;
$container-padding: 40px;
