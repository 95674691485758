@import '../../styles/style-guide';
@import '../../styles/screen-sizes';
@import '../../styles/gradients';

.background-texture {
  position: absolute;
  z-index: -10;

  &.black {
    bottom: 5%;
    left: 0;

    @include on-tablet {
      display: none;
    }
  }

  &.gradient {
    overflow: hidden;
    right: 40px;

    @include on-tablet {
      height: 900px;
      left: 60%;
      top: 60%;
    }

    @include on-mobile {
      left: 70%;
      max-width: 100px;
      right: 0;
      top: 140%;
    }
  }
}
