@import '../../styles/screen-sizes';
@import '../../styles/style-guide';

.contact-page {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: $max-container-width;
  padding: 0 $container-padding;

  @include on-tablet {
    align-items: unset;
    flex-direction: column-reverse;
    height: unset;
    justify-content: unset;
    margin: unset;
    max-width: unset;
    padding: unset;
  }

  .contact-info {
    align-items: center;
    margin-top: 100px;
    z-index: 1;

    @include on-tablet {
      margin-top: unset;
      position: unset;
      right: unset;
      top: unset;
      width: 100%;
    }
  }
}
