@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.list-section {
  margin: 30px 0;

  @include on-mobile {
    margin: 8px 0;
  }

  .list-title {
    font-weight: 600;
  }

  .list {
    max-width: 1000px;

    @include on-tablet {
      max-width: 100%;
    }

    p {
      line-height: 32px;
      margin: 4px 0;

      @include on-mobile {
        margin: 0;
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    .nested-list {
      list-style-image: url('../../../public/assets/list-bullet.png');
      list-style-type: square;
      margin: 4px 0 20px;
      padding-left: 60px;
    }
  }
}
