@import '../../styles/gradients';
@import '../../styles/style-guide';
@import '../../styles/fonts';
@import '../../styles/screen-sizes';

.interactive-card {
  @include button-text;

  background-color: $dark;
  border: 1px solid $light;
  height: 300px;
  width: 100%;

  @include on-mobile {
    @include content-text;
    height: 140px;
  }

  .children-wrapper {
    align-items: center;
    background-color: $dark;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    &:hover {
      @include text-gradient-fill;
      @include text-gradient-clip;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.2s ease;
    }
  }
}
