@import '../../styles/gradients';
@import '../../styles/style-guide';
@import '../../styles/fonts';
@import '../../styles/screen-sizes';


.gradient {
  top: 180px;
}

.about-us-page {
  display: flex;
  flex-direction: column;
  padding-top: 220px;


  @include on-mobile {
    margin-top: 140px;
  }

  * {
    color: $light;
    text-align: left;
  }

  .heading {
    @include big-text;
    display: inline-block;
    text-align: left;

    @include on-mobile {
      @include big-text-mobile;

      > span {
        display: block;
      }
    }

    > span {
      margin-right: 22px;
    }

    &.column {
      display: inline-block;
    }

    &.align-left {
      text-align: left;
    }
  }

  .content {
    @include content-text;
    margin-top: 100px;
    max-width: 1000px;
    text-align: left;

    @include on-mobile {
      @include content-text-mobile;
      margin-top: 60px;
    }
  }

  .principles {
    margin: 200px 0;

    @include on-mobile {
      margin: 100px 0;
    }
  }


  .emp-text {
    @include text-gradient-fill;
    @include text-gradient-clip;

    position: relative;
    text-align: inherit;
  }

  .boxes {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-areas:
      '. item-1 item-2'
      'item-3 item-3 .';
    grid-template-columns: auto 36% 36%;
    margin-top: 80px;

    @include on-tablet {
      grid-template-areas:
        'item-1  item-1 item-1'
        'item-2 item-2 item-2'
        'item-3 item-3 item-3';
      margin-top: 40px;
    }

    .item-1 {
      grid-area: item-1;
    }

    .item-2 {
      grid-area: item-2;
    }

    .item-3 {
      grid-area: item-3;
    }
  }
}
