@mixin on-mobile {
  @media only screen and (max-width: 428px) {
    @content;
  }
}

@mixin on-tablet {
  @media only screen and (max-width: 960px) {
    @content;
  }
}
