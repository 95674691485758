@import '../../styles/screen-sizes';

.text {
  animation: fade-in 2.4s forwards;
  display: flex;
  flex-direction: column;
  opacity: 0%;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  width: 100%;

  .top-element {
    animation: top-element 1.8s forwards cubic-bezier(0.785, 0.135, 0.15, 0.86);
    display: inline-block;
    position: relative;
    z-index: 1;
  }

  .slide {
    display: inline-block;
    overflow: hidden;

    .bottom-element {
      animation: bottom-element 1.8s forwards cubic-bezier(0.785, 0.135, 0.15, 0.86);
      display: inline-block;
      z-index: -1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0%;
    }

    60% {
      opacity: 100%;
    }

    100% {
      opacity: 100%;
    }
  }

  @keyframes top-element {
    0% {
      transform: translateX(25%);
    }

    60% {
      transform: translateX(25%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes bottom-element {
    0% {
      transform: translateX(-160%);
    }

    60% {
      transform: translateX(-160%);
    }

    100% {
      transform: translateX(0%);
    }
  }
}
