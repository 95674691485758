@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
@import './styles/style-guide';

html {
  box-sizing: border-box;
  height: 100%;

  * {
    color: $dark;
    font-family: 'Montserrat', sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    background-color: $dark;
    height: 100%;
    margin: 0;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-container-width;
    padding: 0 $container-padding;
  }
}
