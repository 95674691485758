@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.menu-item {
  .links {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      border-bottom: 4px solid $primary;
      transition: all 0.2s ease-out;
    }
  }
}
