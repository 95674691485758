@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.nav-menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include on-tablet {
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: -100%;
    top: 80px;
    transition: all 0.4s ease;
    width: 100%;

    > .menu-item {
      .links {
        color: $light !important;
      }
    }
  }

  &.active {
    background-color: $dark;
    opacity: 1;
    right: 0;
    transition: all 0.4s ease;
    z-index: 1;
  }

  .menu-item {
    @include on-tablet {
      margin: 60px 0;
      position: relative;
      width: 100%;
    }

    @include on-mobile {
      margin: 20px 0;
    }

    .links {
      margin: 0 20px;

      @include on-tablet {
        font-size: 60px;
        font-weight: 600;
        line-height: 72px;

        &:hover {
          border-bottom: 0;
          color: $primary;
        }
      }

      @include on-mobile {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  &.dark {
    > .menu-item {
      .links {
        color: $light;
      }
    }
  }

  &.light {
    > .menu-item {
      .links {
        color: $dark;
      }
    }
  }

  &.mid-light {
    > .menu-item {
      .links {
        color: $dark;
      }
    }
  }
}
