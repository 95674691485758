@import '../../styles/style-guide';
@import '../../styles/screen-sizes';
@import '../../styles/gradients';

.contact-card {
  background-color: $dark;

  .border {
    @include border-gradient;
    bottom: 12px;
    height: 100%;
    left: 12px;
    position: relative;
    width: 100%;

    @include on-tablet {
      border: 0;
      bottom: unset;
      height: unset;
      left: unset;
      position: unset;
    }
  }

  .card {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 80px;

    @include on-tablet {
      padding: 40px;
    }

    .title {
      color: $light;
      font-size: 40px;
      font-weight: 400;
      line-height: 50px;

      @include on-mobile {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .info {
      font-size: 24px;
      line-height: 32px;
      margin-top: 60px;
      text-align: start;
      width: 500px;

      @include on-mobile {
        font-size: 16px;
        line-height: 20px;
        margin-top: 40px;
        width: 100%;
      }

      p {
        color: $light;
        margin: 0;
      }
    }
  }
}
