@import '../../styles/style-guide';
@import '../../styles/gradients';
@import '../../styles/screen-sizes';

.quote-card-border {
  @include border-gradient;
  height: 70%;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 44%;
  z-index: 2;

  @include on-tablet {
    border: 0;
    height: unset;
    left: unset;
    position: unset;
    top: unset;
    width: unset;
  }

  .quote-card-fill {
    align-items: center;
    background-image: $gradient-fill;
    display: flex;
    height: 100%;
    justify-content: center;
    left: -16px;
    position: relative;
    top: -16px;

    @include on-tablet {
      left: 0;
      top: 0;
      width: 100%;
    }

    .quote-text {
      color: $light;
      font-size: 80px;
      font-weight: 400;
      line-height: 98px;
      margin: auto 30%;
      text-align: start;

      @include on-tablet {
        font-size: 64px;
        line-height: 78px;
        margin: 140px 40px;
      }

      @include on-mobile {
        font-size: 40px;
        line-height: 50px;
        margin: 100px 40px 80px;
      }
    }
  }
}
