@mixin big-text {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 73px;
}

@mixin content-text {
  font-size: 24px;
  letter-spacing: normal;
  line-height: 34px;
}

@mixin button-text {
  font-size: 40px;
  letter-spacing: normal;
  line-height: 49px;
}

@mixin big-text-mobile {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 49px;
}

@mixin content-text-mobile {
  font-size: 16px;
  letter-spacing: normal;
  line-height: 34px;
}
