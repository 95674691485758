@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.footer {
  background-color: $gray;

  .footer-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 24px;
    padding-bottom: 20px;
    padding-top: 60px;

    @include on-tablet {
      flex-direction: column;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      padding-top: 20px;

      > * {
        margin-top: 40px;
        width: 100%;
      }
    }

    .first-row {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      > svg {
        transform: scale(1.6);
        transform-origin: top left;

        @include on-mobile {
          transform: scale(1.4);
        }
      }

      .social {
        display: flex;

        .icon-link {
          margin-right: 20px;
          padding-top: 40px;
        }
      }
    }

    .second-row {
      .menu-item {
        margin: 20px 0;
        text-align: start;
      }
    }

    .third-row {
      text-align: end;

      @include on-tablet {
        text-align: start;
      }

      a {
        color: $dark;
        display: block;
        margin: 20px 0;
        text-decoration: none;
      }
    }

    .copyrights-info {
      margin-top: 40px;
      text-align: start;
      width: 100%;
    }
  }
}
