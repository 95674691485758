@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
