@import '../../styles/style-guide';
@import '../../styles/screen-sizes';

.job-container {
  align-items: flex-start;
  color: $dark;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  justify-content: flex-start;
  line-height: 32px;
  text-align: start;

  @include on-mobile {
    font-size: 16px;
    line-height: 20px;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 24px;
    padding-top: 160px;

    @include on-mobile {
      font-size: 24px;
      line-height: 32px;
      padding-top: 100px;
    }
  }

  .tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    @include on-tablet {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .divider-small {
      align-self: center;
      background-color: $primary;
      content: '';
      height: 2px;
      margin: 0 20px;
      width: 20px;

      @include on-mobile {
        margin: 0 8px;
        width: 12px;
      }
    }
  }

  .description {
    max-width: 1000px;

    @include on-tablet {
      max-width: 100%;
    }

    p {
      margin: 60px 0;

      @include on-mobile {
        margin: 32px 0;
      }
    }

    .divider-big {
      align-self: center;
      background-color: $primary;
      content: '';
      height: 2px;
      margin-bottom: 30px;
      width: 400px;

      @include on-mobile {
        margin-bottom: 8px;
        width: 100%;
      }
    }
  }

  button {
    margin: 100px 0 200px;

    @include on-mobile {
      margin: 60px 0 120px;
    }
  }
}
