@import '../../styles/screen-sizes';

.map-container {
  left: 0;
  position: absolute;
  top: 0;

  @include on-tablet {
    left: unset;
    position: unset;
    top: unset;
  }

  .map {
    height: 100vh;
    width: 100vw;
    z-index: -1;

    @include on-tablet {
      height: 70vh;
    }
  }

  .marker {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
